import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';
import SocialMediaLinks from '../socialMediaLinks'


import './footer.css'

class Footer extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {

		return (

			<footer className="black">
				<Helmet>
					<link rel="stylesheet" href="https://dashboard.lechat.app/assets/widget/styles.css" />
					<script>
						{`
      function initializeWidget() {
        new LeChatWidget({
          companyName: "1714833061133f4743eb3-04ce-497d-ac7d-a97025d6e17eu"
        });
      }
      if (document.readyState === 'complete') {
        initializeWidget();
      } else {
        window.onload = initializeWidget;
      }
    `}
					</script>
					<script async src="https://dashboard.lechat.app/assets/widget/lechat.js" />
				</Helmet>
				<Helmet script={[
					// {"src": "https://www.barcelonacodeschool.com/drift.js"},
					// {'dangerouslySetInnerHTML':`
					// console.log('banana')
					// window.dataLayer = window.dataLayer || [];
					// function gtag(){dataLayer.push(arguments);}
					// gtag('js', new Date());
					// gtag('config', 'AW-856512071');
					// `}
				]} />

				<div className="grid-1-1-1-1 footerNav">
					<div>
						<h2>In-person courses</h2>

						<Link
							to="/barcelona-code-school/javascript-full-stack-bootcamp/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Web / Mobile Development in-person Bootcamp
						</Link>

						<Link
							to="/game-development-bootcamp/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Game Development in-person Bootcamp
						</Link>


						<Link
							to="/ux-design-bootcamp-in-barcelona-code-school/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ UX / UI Design in-person Bootcamp
						</Link>






						<Link
							to="/programming-with-javascript/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Programming with JavaScript
						</Link>


						<Link
							to="/responsive-web-design-with-css3/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Responsive web design / CSS3
						</Link>

						<Link
							to="/reactjs-crash-course/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',

							}}
						>
							→ React.js Crash Course
						</Link>

						<Link
							to="/backend-development-with-node-express-mongodb/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',

							}}
						>
							→ Back-end development with Express
						</Link>



						<Link
							to="/3d-modelling-and-printing/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ 3D Modelling & Printing
						</Link>


						<Link
							to="/cyber-security-for-web-developers/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Cyber Security For Web Developers
						</Link>

						{/* <Link
							to="/bcn-coding-kids/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',

							}}
						>
							→ Coding for kids
						</Link> */}



						{/*				<Link 
				to="/bcn-coding-kids/bcs-young-coders-summer-camp/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Young Coders Summer Camp
			</Link>*/}

					</div>

					<div>
						<h2>Online courses</h2>


						<Link
							to="/barcelona-code-school/javascript-full-stack-online-bootcamp/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Web / Mobile Development Online Mentored Bootcamp
						</Link>




						<Link
							to="/ux-design-online-mentored-bootcamp/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ UX / UI Design Online Mentored Bootcamp
						</Link>

						<Link
							to="/machine-learning-and-ai-engineering-bootcamp/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Deep Learning and AI Engineering Bootcamp
						</Link>

						{/*			<Link 
			to="/advanced-web-develoment-bootcamp/"
			style={{
				textDecoration: 'none',
				marginRight: '1em',
			}}
			>
			→ Advanced Web Development Bootcamp
			</Link>*/}
						<h2 style={{
							marginTop: '1em',
						}}>
							Udemy courses
						</h2>
						<a
							href="https://www.udemy.com/course/html-and-css-for-the-total-newbies/?referralCode=3463E1667BB1FD9D016C"
							target='_blank'
							rel='noreferrer noopeners'
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ HTML & CSS for the total newbies
						</a>

						<a
							href="https://www.udemy.com/course/programming-with-python-bcs/?referralCode=9B251D06407ACC8D5DBC"
							target='_blank'
							rel='noreferrer noopeners'
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Programming with Python
						</a>
						<a
							href="https://www.udemy.com/course/real-time-apps-with-socket-io-react-and-express/?referralCode=08B75162BEC2699422FF"
							target='_blank'
							rel='noreferrer noopeners'
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Real-time apps with Socket.IO
						</a>
						<a
							href="https://www.udemy.com/course/sending-emails-with-nodemailer-gmail-and-oauth2/?referralCode=53A07520999CD0870D6B"
							target='_blank'
							rel='noreferrer noopeners'
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→  Nodemailer, Gmail and OAuth2
						</a>


						{/* <Link
							to="/online-short-courses/programming-with-javascript-online-course/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Programming with JavaScript Online Course
						</Link>

						<Link
							to="/online-short-courses/react-crash-course-online/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ React.js Online Crash Course
						</Link>

						<Link
							to="/online-short-courses/backend-development-with-node-express-mongodb-online/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Back-end development with Express Online course
						</Link>

						<Link
							to="/online-short-courses/responsive-web-design-with-css3-online/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Responsive web design with HTML5/CSS3
						</Link> */}


					</div>

					<div>
						<h2>School</h2>
						{/*<Link 
				to="/calendar/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Calendar
			</Link>*/}

						<Link
							to="/contacts/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Contacts
						</Link>

						<Link
							to="/about-us/school/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ About us
						</Link>

						<Link
							to="/job-offer/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Hire developers
						</Link>

						<Link
							to="/corporate-training/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ For companies
						</Link>

						<Link
							to="/about-us/testimonials/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Testimonials
						</Link>
						<Link
							to="/blog/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Blog
						</Link>
						<Link to="/lab/" style={{ textDecoration: 'none', marginRight: '1em' }}>
							→ BCS Lab
						</Link>
						<Link
							to="/about-us/terms-and-conditions/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Terms and Conditions
						</Link>
						<Link
							to="/about-us/privacy-policy/"
							style={{
								textDecoration: 'none',
								marginRight: '1em',
							}}
						>
							→ Privacy Policy
						</Link>
					</div>

					<div>
						<h2>Get in touch</h2>
						<SocialMediaLinks color={'white'} />
						<a href='https://zcal.co/i/c9mEbhSA' target='_blank' style={{ marginBottom: '1em' }}>→ Book a meeting/call with us</a>
						<a href='mailto:office@barcelonacodeschool.com' style={{ marginBottom: '1em' }}>→ office@barcelonacodeschool.com</a>
						<p>→Our campus:</p>
						<p>Carrer de Paris 157, 08036, Barcelona, Spain</p>
						<p></p>
						<p>→ Give us a call:</p>
						<a href="tel:+34936639807" style={{ marginBottom: '1em' }}>+34 936 639 807</a>
						<p>Mon-Fri, 9:00-18:00</p>
						<a href='https://zcal.co/i/3qm10eD2' target='_blank' style={{ marginBottom: '1em' }}>→ Open House every Thursday, RSVP here</a>
					</div>

				</div>

				<p className="copyrights">Copyright © 2015-{(new Date).getFullYear()} — Barcelona Code School | Upgrade Yourself!</p>
				<p className="legalname">Arms and Legs FOM SL | B66750365</p>
			</footer>
		)
	}
}

export default Footer
